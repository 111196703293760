.LetschatPage {
	padding-bottom:4em;
	.colour-bar {
		margin-top:2em;
	}
	.full-width-image {
		margin-top:2em;
	}
	h1 {
		margin-bottom:0;
	}
}