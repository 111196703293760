nav {
	display:flex;
	justify-content: center;
	padding:1em 0;
    ul {
		display: flex;
        justify-content: center;
        flex-wrap:wrap;
        list-style: none;
        padding:0;

        li {
        	a {
        		font-size:14px;
        		color:$black;
        		display:block;
        		padding:.7em 1em;

        		&:hover {
        			color:$pink;
        			text-decoration: none;
        		}
        	}
        }
    }
}
