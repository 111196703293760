.content-container {
	padding:3em 2.5%;

	.blog-search-top {
		display:block;
		width:100%;
		padding:0;
		margin:0 auto;
	}
	.blog-search-side {
		display:none;
	}

	.blog-entries {
		display:flex;
		flex-wrap:wrap;
		flex-basis: 72%;
	}

	.blog-widget-block {
		flex-basis: 28%;
		padding:0 7.5% 0 3.25%;
	}

	.post-summary {
		text-align:center;
	}

	.post-image {
		img{
			max-height:360px;
			max-width:100%;
			display:block;
			margin:0 auto;
			overflow:hidden;
		}
	}

	.member-profile {
		display:flex;
		
	}

	.profile-image {
		img {
			border-radius: 50%;
		}
	}

	.profile-summary {
		padding:0 3em;
		p {
			margin-top:0;
		}

	}

	.blog-recent-post {
		display:flex;
		margin:1em 0 ;
		.post-summary-image {
			width:30%;
			max-width:100px;

			img {
				width:100%;
				border-radius: 50%;
			}
		}
		.blog-recent-post-summary {
			width:70%;
			padding-left:1em;
			p {
				margin: 0 0 .3em;

				&.blog-post-meta {
					font-size: 12px;
				}
			}
		}
	}

	.blog-tags {

		ul {
			list-style:none;
			padding:0;

			&.tags-list {
				li {
					display:inline;
					padding:0 .5em 0 0;
				}

			}

			&.categories-list {
				li {
					span {
						float:right;
					}
				}
			}

		}
	}

	article {
		h1 {
			margin-bottom:0;
		}
		.blog-post-meta {
			margin-top:0;
		}
	}
}


.blog-search {
	padding:0 6%;
	&-side {
		width:100%;
		padding:1em 0;
	}

	form {
		position:relative;
	}
	fieldset {
		display:inline;
		width:100%;
	}
	input[type='text'] {
		border:none;
		border-bottom:1px solid #000;
		background-color:transparent;
		height:40px;
		width:100%;
		color:#000;
		/* ms clear x */
		&::-ms-clear {
			display:none;
		}

		&:focus {
			outline:none;
		}
	}
	.Actions {
		display:inline;
	}
	input[type='submit'] {
		background:none;
		border:none;
		position:absolute;
		font-size:24px;
		font-family: 'icomoon';
		right:0;
		top:0;
		width:30px;
		color:#000;

		&:hover {
			color:$pink;
		}

	}
}

@media (min-width:500px) {
	.content-container {
		.blog-search-top {
			width:80%;
		}
	}
}

@media (min-width:768px) {
	.content-container {
		.blog-search-top {
			width:60%;
		}
	}
	.blog-summary-list {
		display:flex;
		flex-wrap:wrap;
		.post-summary {
			width:48%;
			margin:0 1%;
		}
		.post-summary:nth-child(5n+1) {
			width:100%;
		}
	}
}

@media (min-width:992px) {
	.content-container {
		.blog-search-top {
			display:none;
		}
		display:flex;
		padding:3em 7.5%;

		.blog-search-side {
			display:block;
		}
		.blog-entries {
			padding:0 3.25% 0 7.5%;
		}
	}
}
