.btn-tp {
	display:inline-block;
	margin:4em auto;
	font-size:14px;
	line-height:1.8;
	color:$black;
	border:1px solid $black;
	border-radius:14px;
	width:auto;

	&:hover {
		border-color:$pink;
		color:$pink;
	}
}

.btn-teal {
	border-color:$teal-border;
	color: $teal-border;
}

.btn-letschat {
	position:fixed;
	bottom:50px;
	right:6%;
}

