.colour-bar {
	display:flex;
	justify-content: center;
	margin-bottom:3.5em;
	& > div {
		height:6px;
		width:16px;
	}
	:nth-child(1){background-color:#c6295a;}
	:nth-child(2){background-color:$pink;}
	:nth-child(3){background-color:$teal;}
	:nth-child(4){background-color:#f7efbb;}
	:nth-child(5){background-color:#1f1c1e;}
	:nth-child(6){background-color:#1f1c1e;}
	:nth-child(7){background-color:$white;}
	:nth-child(8){background-color:#1f1c1e;}
	:nth-child(9){background-color:$pink;}

	& + blockquote {
		h2{
			margin-top:0;
		}
	}
}