.TeamMemberPage {
	h1 {
		margin-bottom:0;
	}
	h3 {
		font-size:20px;
	}
	.full-width-image {

		img {
			border-radius:50%;
		}
	}
}
.team-grid {
	display:flex;
	justify-content: left;
	flex-wrap:wrap;
	max-width:1800px;
	margin:0 auto;
}

.team-grid-item {
	flex-basis: 100%;
	position:relative;
	cursor:pointer;

	@media (min-width:768px) {
		flex-basis: 50%;
	}

	h2 {
		position:absolute;
		width:100%;
		text-align:center;
		left:0;
		top:40%;
		color:$white;
		font-size:36px;
		text-transform:uppercase;

		@media (min-width:500px) {
			font-size:50px;
			top:30%;
		}
		@media (min-width:768px) {
			font-size:36px;
			top:40%;
		}
		@media (min-width:992px) {
			font-size:50px;
			top:30%;
		}
	}

	h3 {
		position:absolute;
		width:100%;
		text-align:center;
		padding:0 6%;
		left:0;
		top:67%;
		color:$white;
		font-size:20px;

		@media (min-width:500px) {
			font-size:20px;
			top:67%;
			padding:0 6%;
		}

		@media (min-width:768px) {
			font-size:20px;
			top:67%;
			padding:0 6%;
		}
		@media (min-width:992px) {
			font-size:24px;
			top:60%;
			padding:0 20%;
		}
	}

	img {
		width:100%;
	}
}

.team-member-image {
	padding: 2em 6%;
	margin: 0 auto;

	img {
		max-width:500px;
		width:100%;
	}
}

