.main {
	padding-bottom:4em;
}
.main-content {
	text-align:center;
	max-width:650px;
	padding: 0 6%;
	h1 {

	}

	p {

	}

  img {
    width:100%;
    height: auto;
  }
}
