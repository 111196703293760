.top-quote {
	text-align:center;
	font-size:32px;
	max-width:720px;
	padding:.7em 6% 1.4em;
	line-height:1.2;
}

@media (min-width:600px) {
	.top-quote{
		font-size:42px;
	}
	
}

.bottom-quote {
	background-color:$ochre;
	text-align:center;
	padding:1.4em 0;
	&-content {
		max-width:650px;
		margin:.7em auto;
		padding:0 6%;
		font-size:22px;

	    quotes:"“" "”";

	    &:before{
	    	content: open-quote;
	    	margin-right:-.2em;
	    }
	    &:after{
	    	content: close-quote;
	    	margin-left:-.2em;
	    }
	}
	&-author {
		max-width:650px;
		padding:0 6%;
		margin:1em auto;
		font-size:14px;
	}
}

@media (min-width:600px) {
	.bottom-quote {

		&-content {
			font-size:28px;
		}
	}
}

.full-width-image {
	text-align:center;
	padding-top:1em;

	img {
		max-width:100%;
	}
}

.teal-bg {
	background-color:$teal-bg;
}
.coral-bg {
	background-color:$coral-bg;
}

.highlight {

	&-pink span {
		color:$pink;
	}
	&-yellow span {
		color:$yellow;
	}
	&-coral span {
		color:$coral;
	}
	&-teal span {
		color:$teal;
	}
	&-white span {
		color:$white;
	}
}

.content-numbering {
	width:36px;
	height:36px;
	border-radius:50%;
	font-size:25px;
	font-weight:700;
	line-height:1.1;
	margin:2em auto 1em;

}
