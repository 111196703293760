#MemberLoginForm_LoginForm {
	max-width:300px;
	padding:2em 2%;
	margin:0 auto;

	input[type='checkbox'] {
		margin-left:0;
	}
}


@media (min-width:992px) {
	#MemberLoginForm_LoginForm {
	}
	
}
