@import url('https://fonts.googleapis.com/css?family=Playfair+Display:400,400i,700,700i');

@font-face {
    font-family: 'MillerDisplay';
    src: url('/mysite/css/fonts/miller-displayroman-webfont.woff') format('woff'), 
    	url('/mysite/css/fonts/miller-displayroman-webfont.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'MillerDisplay';
    src: url('/mysite/css/fonts/miller-displayitalic-webfont.woff') format('woff'), 
    	url('/mysite/css/fonts/miller-displayitalic-webfont.woff2') format('woff2');
    font-weight: normal;
    font-style: italic;
}
@font-face {
    font-family: 'icomoon';
    src: url('/mysite/css/fonts/icomoon.eot?#iefix') format('embedded-opentype'), 
        url('/mysite/css/fonts/icomoon.woff') format('woff'), 
        url('/mysite/css/fonts/icomoon.ttf') format('truetype'), 
        url('/mysite/css/fonts/icomoon.svg#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

/* glyphs: 
    r-arrow: \e900 
    r-arrow2: \ea3c
    l-arrow: \e901
    l-arrow2: \ea40
    search: \e902
    */
.ico {
    font-family: 'icomoon';
}

.ico-arrow-left:before { content:"\e900"; }
.ico-arrow-right:before { content:"\e901"; }
.ico-search:before { content:"\e902"; }

