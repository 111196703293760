header {
	.main-logo {
		margin-top:3em;
		display:flex;
		justify-content:center;
		position:relative;
		padding:0 0 2em;

		&:after {
			content:" ";
			position:absolute;
			bottom:0;
			left:calc(50% - 20px);
			height:6px;
			width:20px;
			background-color:$pink; 
		}
		&:before {
			content:" ";
			position:absolute;
			bottom:0;
			left:50%;
			height:6px;
			width:20px;
			background-color: $teal;
		}

		img {
			width:100%;
			max-width:200px;
		}
	}
}
