.WhatwedoPage {

	.colour-bar {
		margin-top:5em;
	}
	.main-content {
		padding-bottom:4em;

		blockquote {
			border:none;
		}
	}
}
.whatwedo-block1 {
	.content-numbering {
		color:$white;
		background-color:$red-bg;
		margin-bottom:1em;
	}	
}

.whatwedo-block2 {
	background-color:#9cc9c6;
	.content-numbering {
		color:#9cc9c6;
		background-color:$white;
	}
	blockquote {
		color:$white;
	}	
}
.whatwedo-block3 {
	.content-numbering {
		color:$white;
		background-color:#9cc9c6;
		line-height:1;
	}	
}
.whatwedo-block4 {
	background-color:#f2c5c4;
	.content-numbering {
		color:#f2c5c4;
		background-color:$white;
		line-height:1;
		padding-right:1px;
	}	
}
.whatwedo-block5 {
	.content-numbering {
		color:$white;
		background-color:$red-bg;
	}	
}
