body {
	/*font-family:'MillerDisplay', serif;*/
	font-family: 'Playfair Display', serif;
	font-size:16px;
	font-weight:normal;
}

h1,h2,h3,h4 {
	line-height:1.1;
	margin:1.2em 0 .8em;
}

h1 {
	font-size:32px;
}
h2 {
	font-size:26px;
}
h3 {
	font-size:21px;
}
h4 {
	font-size:18px;
}

blockquote {
	font-size: 30px;
	line-height: 1.1;
	margin-bottom:0;
	padding-bottom:0;
}

p {
	font-size:16px;
	margin:1.2em 0;
}

a {
	color:$pink;
}

@media (min-width:600px) {
	h1 { font-size:40px; }
	h2 { font-size:30px; }
	h3 { font-size:24px; }
}
