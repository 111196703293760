
.manifesto-items {
	display:flex;
	flex-wrap: wrap;
	justify-content:space-between;
	background-color:#f1f8f8;
	max-width:1440px;
	margin:0 auto 3em;
	padding:0 0 2em;

	h2 {
		font-size:37px;
		line-height:1.3;
		text-align: center;
		flex-basis:100%;
		padding:0 10%;
		margin-bottom:0;
	}
}

.manifesto-item {
	flex-basis: 100%;
	margin-top:2em;
	.content-numbering {
		color:$white;
		text-align:center;
	}
	&:nth-child(6n+1) .content-numbering {	background-color:$teal;	}
	&:nth-child(6n+2) .content-numbering {	background-color:$yellow;	}
	&:nth-child(6n+3) .content-numbering {	background-color:$ochre;	}
	&:nth-child(6n+4) .content-numbering {	background-color:$coral;	}
	&:nth-child(6n+5) .content-numbering {	background-color:$mid-gray;	}
	&:nth-child(6n+6) .content-numbering {	background-color:$teal-bg;	}

	&:last-child .manifesto-item-text {
		font-style: italic;
	}
}

.manifesto-item-text {
	text-align:center;
	margin:0 auto;
	padding:0 10%;
}

@media (min-width:500px) {
	.manifesto-items {
		h2 {
			font-size:60px;
		}
	}
	.manifesto-item {
		flex-basis: 50%;

		&-text {
			padding:0 16%;
		}
	}
}

@media (min-width:992px) {
	.manifesto-item {
		flex-basis: 33%;
	}
}